'use client';

import { ComponentPropsWithoutRef, ComponentType, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import { createSafeContext, useSafeContext } from '@utilities/context';

import { GridTableRowProvider, useGridTableRow } from './GridTableRowProvider';

export type GridTableData = {
  [key: string]: any;
};

export interface GridTableHeaderItem<Data extends GridTableData, Meta = undefined> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  name: keyof Data | (string & {});
  label: ReactNode;
  cellClassName?: string;
  headCellClassName?: string;
  bodyCellClassName?: string;
  hidden?: (params: { meta?: Meta }) => boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  render?: (params: {
    item: Data;
    rowIndex: number;
    headerItem: GridTableHeaderItem<GridTableData, Meta>;
    meta?: Meta;
    // eslint-disable-next-line @typescript-eslint/ban-types
    name: keyof Data | (string & {});
  }) => ReactNode;
  meta?: Meta;
  Component?: ComponentType<{
    item: Data;
    rowIndex: number;
    colIndex: number;
    meta?: Meta;
    headerItem: GridTableHeaderItem<GridTableData, Meta>;
  }>;
}

interface ContextValue<Data extends GridTableData = GridTableData, Meta = undefined> {
  header: GridTableHeaderItem<Data, Meta>[];
  data?: GridTableData[];
  rowId?: keyof Data | ((params: { row: GridTableData }) => number | string);
  rowClassName?: string;
  cellClassName?: string | ((params: { rowIndex: number; colIndex: number }) => string | undefined);
  bodyRowClassName?: string | ((params: { item: Data; rowIndex: number }) => string | undefined);
  headRowClassName?: string;
  bodyCellClassName?: string;
  headCellClassName?: string;
  meta?: Meta;
  HeadRowComponent?: ComponentType<{ className?: string }>;
  BodyRowComponent?: ComponentType<{ className?: string }>;
}

const Context = createSafeContext<ContextValue>();

export const useGridTable = () => useSafeContext(Context);

export type GridTableRowProps = ComponentPropsWithoutRef<'div'>;

export const GridTableRow = ({ className, children, ...props }: GridTableRowProps) => {
  const { rowClassName } = useGridTable();

  return <div className={classNames(rowClassName, className)} {...props} />;
};

export type GridTableProps<
  Data extends GridTableData,
  Meta = undefined,
> = ComponentPropsWithoutRef<'div'> &
  ContextValue<Data, Meta> & {
    indexClassName?: { [key: number]: string };
  };

// TODO: make rowIndex and colIndex passed through context (CellContext)
export const GridTableBodyCell = ({
  className,
  rowIndex,
  colIndex,
  ...props
}: ComponentPropsWithoutRef<'div'> & { rowIndex: number; colIndex: number }) => {
  const { bodyCellClassName, cellClassName } = useGridTable();

  return (
    <div
      className={classNames(
        'grid-table-cell',
        typeof cellClassName === 'function' ? cellClassName({ rowIndex, colIndex }) : cellClassName,
        bodyCellClassName,
        className,
      )}
      {...props}
      data-qa="karma-guide-cell"
    />
  );
};

export const GridTableBodyRow = ({ className, ...props }: ComponentPropsWithoutRef<'div'>) => {
  const { rowClassName, bodyRowClassName } = useGridTable();
  const { item, rowIndex } = useGridTableRow();

  return (
    <div
      className={classNames(
        'grid-table-row',
        rowClassName,
        typeof bodyRowClassName === 'function'
          ? bodyRowClassName({ item, rowIndex })
          : bodyRowClassName,
        className,
      )}
      {...props}
    />
  );
};

export const GridTableBodyRowCells = () => {
  const { header, meta } = useGridTable();
  const { item, rowIndex } = useGridTableRow();

  return (
    <>
      {header.map((headerItem, colIndex) => {
        const {
          name,
          Component,
          bodyCellClassName,
          cellClassName,
          render: value,
          hidden,
        } = headerItem;

        if (hidden && hidden({ meta })) {
          return null;
        }

        return (
          <GridTableBodyCell
            rowIndex={rowIndex}
            colIndex={colIndex}
            className={classNames(cellClassName, bodyCellClassName)}
            key={name}
          >
            {Component && (
              <Component
                headerItem={headerItem}
                rowIndex={rowIndex}
                colIndex={colIndex}
                item={item}
                meta={meta}
              />
            )}
            {!Component && (value ? value({ item, rowIndex, meta, name, headerItem }) : item[name])}
          </GridTableBodyCell>
        );
      })}
    </>
  );
};

export const BaseGridTableBodyRow = ({ ...props }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <GridTableBodyRow {...props}>
      <GridTableBodyRowCells />
    </GridTableBodyRow>
  );
};

export const BaseGridTableHeadRow = ({ className, ...props }: ComponentPropsWithoutRef<'div'>) => {
  const { rowClassName, header, meta, cellClassName, headCellClassName, headRowClassName } =
    useGridTable();

  return (
    <div className={classNames(rowClassName, headRowClassName, className)} {...props}>
      {header.map((headerItem, colIndex) => {
        const { hidden } = headerItem;

        if (hidden && hidden({ meta })) {
          return null;
        }

        return (
          <div
            className={classNames(
              'grid-table-cell',
              // rowIndex is -1 because it's header
              typeof cellClassName === 'function'
                ? cellClassName({ rowIndex: -1, colIndex: colIndex })
                : cellClassName,
              headCellClassName,
              headerItem.cellClassName,
              headerItem.headCellClassName,
            )}
            key={headerItem.name}
          >
            {headerItem.label}
          </div>
        );
      })}
    </div>
  );
};

export const GridTable = <Data extends GridTableData, Meta>({
  className,
  header,
  data,
  children,
  rowClassName,
  cellClassName,
  bodyRowClassName,
  bodyCellClassName,
  headCellClassName,
  headRowClassName,
  rowId,
  BodyRowComponent = BaseGridTableBodyRow,
  HeadRowComponent = BaseGridTableHeadRow,
  meta,
  indexClassName,
  ...props
}: GridTableProps<Data, Meta>) => {
  const value = useMemo(() => {
    return {
      header: header as any as GridTableHeaderItem<GridTableData, any>[],
      rowClassName: classNames('grid-table-row', rowClassName),
      cellClassName,
      bodyRowClassName: bodyRowClassName as any,
      bodyCellClassName,
      headCellClassName,
      headRowClassName,
      data,
      rowId: rowId as any as keyof GridTableData,
      meta: meta as any,
      HeadRowComponent,
      BodyRowComponent,
    };
  }, [
    header,
    data,
    rowClassName,
    rowId,
    cellClassName,
    bodyRowClassName,
    bodyCellClassName,
    headCellClassName,
    headRowClassName,
    HeadRowComponent,
    BodyRowComponent,
    meta,
  ]);

  return (
    <Context.Provider value={value}>
      <div className={classNames('grid-table', className)} {...props}>
        {HeadRowComponent !== null && <HeadRowComponent />}

        {BodyRowComponent !== null &&
          data?.map((dataItem, i) => {
            let key: string | number = i;

            if (typeof rowId === 'function') {
              key = rowId({ row: dataItem });
            } else if (typeof rowId === 'string') {
              key = dataItem[rowId];
            }

            const classNameForIndex: string = indexClassName?.[i] || '';

            return (
              <GridTableRowProvider meta={meta} key={key} rowIndex={i} item={dataItem}>
                <BodyRowComponent className={classNameForIndex} />
              </GridTableRowProvider>
            );
          })}
        {children}
      </div>
    </Context.Provider>
  );
};
