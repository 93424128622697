'use client';

import {
  cloneElement,
  Dispatch,
  FocusEvent,
  HTMLProps,
  isValidElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import MenuList, { MenuListProps } from '@uikit/components/MenuList/MenuList';

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { createSafeContext } from '@utilities/context';

interface MenuContextValue {
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>;
  activeIndex: number | null;
  setActiveIndex: Dispatch<React.SetStateAction<number | null>>;
  isOpen: boolean;
}

const MenuContext = createSafeContext<MenuContextValue>();

export const useDropdownMenu = () => useContext(MenuContext);

export interface DropdownMenuProps extends MenuListProps {
  nested?: boolean;
  showOnHover?: boolean;
  children?: ReactNode;
  trigger:
    | ReactNode
    | (({ isOpen }: { isOpen: boolean; triggerProps: Record<string, unknown> }) => JSX.Element);
  placement?: Placement;
  isOpen?: boolean;
  shouldMatchTriggerWidth?: boolean;
  onClose?: () => void;
  variant?: 'primary' | 'secondary';
}

// NOTE: based on this example https://codesandbox.io/s/admiring-lamport-5wt3yg
const DropdownMenu = ({
  className,
  placement = 'bottom-start',
  trigger,
  isOpen: controlledIsOpen,
  onClose,
  showOnHover,
  variant,
  shouldMatchTriggerWidth,
  ...props
}: DropdownMenuProps) => {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const elementsRef = useRef<Array<HTMLButtonElement | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);
  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const item = useListItem();
  const isNested = parentId !== null;
  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;

  const setControlledIsOpen = (open: boolean) => {
    if (onClose && !open) {
      onClose();
    }
  };

  const setIsOpen =
    typeof controlledIsOpen === 'boolean' ? setControlledIsOpen : setUncontrolledIsOpen;
  const { floatingStyles, refs, context } = useFloating<HTMLDivElement>({
    nodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: isNested ? 'right-start' : placement,
    middleware: [
      offset({ mainAxis: isNested ? 0 : 4, alignmentAxis: isNested ? -4 : 0 }),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          if (shouldMatchTriggerWidth) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          }
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const hover = useHover(context, {
    enabled: !showOnHover ? isNested : showOnHover,
    delay: { open: 75 },
    handleClose: safePolygon({ blockPointerEvents: true }),
  });
  const click = useClick(context, {
    event: 'mousedown',
    toggle: !isNested,
    ignoreMouse: isNested,
  });
  const role = useRole(context, { role: 'menu' });
  const dismiss = useDismiss(context, { bubbles: true, ancestorScroll: true });
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    nested: isNested,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    onMatch: isOpen ? setActiveIndex : undefined,
    activeIndex,
  });
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    hover,
    click,
    role,
    dismiss,
    listNavigation,
    typeahead,
  ]);

  // Event emitter allows you to communicate across tree components.
  // This effect closes all menus when an item gets clicked anywhere
  // in the tree.
  useEffect(() => {
    if (!tree) {
      return;
    }

    function handleTreeClick() {
      setIsOpen(false);
    }

    function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        setIsOpen(false);
      }
    }

    tree.events.on('click', handleTreeClick);
    tree.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree.events.off('click', handleTreeClick);
      tree.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId, setIsOpen]);

  useEffect(() => {
    if (isOpen && tree) {
      tree.events.emit('menuopen', { parentId, nodeId });
    }
  }, [tree, isOpen, nodeId, parentId]);

  return (
    <FloatingNode id={nodeId}>
      {isValidElement(trigger) &&
        cloneElement(
          trigger,
          getReferenceProps({
            ...trigger.props,
            /* eslint-disable-next-line react-hooks/rules-of-hooks */
            ref: useMergeRefs([refs.setReference, item.ref, (trigger as any)?.ref]),
            onFocus(event: FocusEvent<HTMLDivElement>) {
              props.onFocus?.(event);
            },
          }),
        )}

      {typeof trigger === 'function' &&
        trigger({
          isOpen,
          triggerProps: getReferenceProps({
            /* eslint-disable-next-line react-hooks/rules-of-hooks */
            ref: useMergeRefs([refs.setReference, item.ref]),
            onFocus(event: FocusEvent<HTMLDivElement>) {
              props.onFocus?.(event);
            },
          }),
        })}
      <MenuContext.Provider
        value={{
          activeIndex,
          setActiveIndex,
          getItemProps,
          isOpen,
        }}
      >
        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          {isOpen && (
            <FloatingPortal>
              <FloatingFocusManager
                context={context}
                modal={false}
                initialFocus={isNested ? -1 : 0}
              >
                <MenuList
                  ref={refs.setFloating}
                  className={classNames(
                    'focus:outline-0 z-popover',
                    variant !== 'secondary' &&
                      'rounded-[4px] bg-neutral-50 min-w-[250px] shadow-popover',
                    className,
                  )}
                  style={floatingStyles}
                  variant={variant}
                  {...getFloatingProps(props)}
                />
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </FloatingList>
      </MenuContext.Provider>
    </FloatingNode>
  );
};

export default DropdownMenu;
