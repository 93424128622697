'use client';

import {
  ComponentPropsWithRef,
  ComponentType,
  ElementRef,
  forwardRef,
  ReactNode,
  Ref,
  useMemo,
} from 'react';

import useUniqueId from '@uikit/utils/hooks/useUniqueId';

import { createSafeContext, useSafeContext } from '@utilities/context';

import FormControlHelperText from './FormControlHelperText';
import FormControlLabel from './FormControlLabel';

interface ContextValue {
  id: string;
  isError?: boolean;
  isSuccess?: boolean;
  disabled?: boolean;
  required?: boolean;
  visuallyRequired?: boolean;
}

const FormControlContext = createSafeContext<ContextValue>();

export const useFormControl = () => useSafeContext(FormControlContext);

export type BaseFormControlFieldProps = Partial<ContextValue>;

export type FormControlProps<
  FieldComponent extends ComponentType<BaseFormControlFieldProps>,
  FieldProps = ComponentPropsWithRef<FieldComponent>,
> = {
  id?: string;
  helperText?: ReactNode;
  label?: ReactNode;
  children?: ReactNode;
  required?: boolean;
  visuallyRequired?: boolean;
  labelClassName?: string;
  Field?: ComponentType<FieldProps>;
} & FieldProps &
  BaseFormControlFieldProps;

export const FormControl = <
  FieldComponent extends ComponentType<BaseFormControlFieldProps>,
  FieldProps = ComponentPropsWithRef<FieldComponent>,
>(
  {
    id,
    label,
    helperText,
    isError,
    isSuccess,
    disabled,
    Field,
    required,
    visuallyRequired,
    labelClassName,
    ...props
  }: FormControlProps<FieldComponent, FieldProps>,
  ref: Ref<ElementRef<FieldComponent>>,
) => {
  const fieldId = useUniqueId(id);
  const value = useMemo(() => {
    return {
      id: fieldId,
      isError,
      isSuccess,
      disabled,
      required,
      visuallyRequired,
    };
  }, [fieldId, isError, visuallyRequired, required, isSuccess, disabled]);

  return (
    <FormControlContext.Provider value={value}>
      {label && <FormControlLabel className={labelClassName}>{label}</FormControlLabel>}
      {Field ? (
        <Field
          ref={ref}
          {...(props as FieldProps)}
          isError={isError}
          isSuccess={isSuccess}
          disabled={disabled}
          required={required}
          id={fieldId}
        />
      ) : (
        props.children
      )}
      {helperText && <FormControlHelperText>{helperText}</FormControlHelperText>}
    </FormControlContext.Provider>
  );
};

export default forwardRef(FormControl) as <
  FieldComponent extends ComponentType<BaseFormControlFieldProps>,
  FieldProps = ComponentPropsWithRef<FieldComponent>,
>(
  props: FormControlProps<FieldComponent, FieldProps>,
) => ReturnType<typeof FormControl>;
