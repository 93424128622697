'use client';

import { ReactNode, useMemo } from 'react';

import { createSafeContext, useSafeContext } from '@utilities/context';

import { GridTableData } from './GridTable';

interface ContextValue<Data extends GridTableData = GridTableData, Meta = undefined> {
  rowIndex: number;
  item: Data;
  meta?: Meta;
}

const Context = createSafeContext<ContextValue>();

export const useGridTableRow = <Data extends GridTableData = GridTableData, Meta = undefined>() =>
  useSafeContext<ContextValue<Data, Meta>>(Context as any);

export interface GridTableRowProviderProps<Data extends GridTableData, Meta = undefined>
  extends ContextValue<Data, Meta> {
  children?: ReactNode;
}

export const GridTableRowProvider = <Data extends GridTableData, Meta = undefined>({
  item,
  rowIndex,
  children,
  meta,
}: GridTableRowProviderProps<Data, Meta>) => {
  const value = useMemo(
    () => ({
      item,
      rowIndex,
      meta,
    }),
    [item, meta],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
